// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.error-message{
    color:#ff0000;
    margin: 0px;
}



.profile{
    .title{
        width: 150px;
    }
}
.demo-message{
    
    position: fixed; 
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-color: rgba(255, 38, 0, 0.404);
    color: white
}

.feature-table{
    .card-header{
        //background-image: linear-gradient(-45deg, $primary,$primary_gradient);
        background-color: $primary;
    }
    h5{
        color:$primary          
    }
    svg{
        max-width: 50px;
        min-width: 20px;
        fill: $gray-200;
    }
    &.pushka{
        .card-header{
            background-image: linear-gradient(-45deg, $primary,$primary_gradient);
        }
        h5{
            color:$black
        }  
        svg{   
            fill: $gray-200;
        }
    }
}
.img-about-top{
    border-radius: 20px;
    width: 100%;
    object-fit: cover;
    height: 280px;
}
.img-about-bottom{
    border-radius: 20px;
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.about-image-grid{
    max-width: 350px;
    .charity-icon{
        position: absolute;
        display: flex;
        align-items:center;
        justify-content: center;
        // border-radius: 4px;
        right: -20px;
        top: 0px;
        z-index: 1;
        // width: 80px;
        // height: 80px;
        // background-color: $white;
    }
}

.about-image-quote{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    flex-shrink: 0;
}

.swatch {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: black;
}

.svg-preview{
    max-width: 400px;
}

.dropzone{
  flex: 1;
  display: flex;
  flex-direction:column;
  align-items: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: $gray-600;
  border-style: dashed;
  background-color: $gray-200;
  color:$gray-600;
  outline: none;
  transition: border .24s ease-in-out
}

.img-tile-preview{
    max-width: 300px;
}

.dashboard-img{
    width: 80px;
}
.multiplier{
    .btn{
        border-radius: 50%;
        height:40px;
        width:40px;
        padding: 0;
        margin-left: 3px;
        margin-right: 3px;
        background-color: $gray-500;
        border-color: $gray-500; 
        &.selected{
            background-color: $primary;
            border-color: $primary; 
        }
    }
}

.tooltip-icon{
    width:22px;
    height:22px;
    padding: 0;
    border-radius: 50%  ;
    margin-left: 3px;
    margin-right: 3px;
}

.add-card-button{
    width:50px;
    height:50px;
    border-radius: 50%  ;
    background-color: $white;
    border-color: $primary-light;
    border-style: dashed;
    border-width:3px;
    svg{
        color: $primary-light;
    }
}

.img-charity{
    width: 100%;
    object-fit: cover;
    height: 400px;
}
@media all and (max-width: 2000px) {
    .img-charity{
        height: 300px;
    }
  }
@media all and (max-width: 1480px) {
    .img-charity{
        height: 200px;
    }
  }
  @media all and (max-width: 1140px) {
    .img-charity{
        height: 175px;
    }
  }
@media all and (max-width: 960px) {
    .img-charity{
        height: 150px;
    }
  }
@media all and (max-width: 600px) {
    .img-charity{
        height: 25vw;
    }
  }

.dashboard-scene{
  background-image: linear-gradient($white, $primary-light);
  overflow:hidden;
}

.scene-foreground{
    max-width: 600px;
}

.flame-background{
    opacity: .1; 
    position: absolute;
    bottom: -100px;
    width: 600px;
    left: -40px;
}
@media all and (max-width: 1140px) {
    .flame-background{
        width: 400px;
        bottom: -50px;
        left: -50px;
    }
  }
@media all and (max-width: 600px) {
    .flame-background{
        width: 300px;
        bottom: -50px;
        left: -50px;
    }
  }

  
.img-cloudinary-modal{

  height: 150px;
  max-width: 160px;
  flex-basis: 120px;
  flex-grow: 1;
  object-fit: cover;
}

.img-preview{
    background-image:
    linear-gradient(45deg, lightgrey 25%, transparent 25%), 
    linear-gradient(135deg, lightgrey 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, lightgrey 75%),
    linear-gradient(135deg, transparent 75%, lightgrey 75%);
  
  background-size: 20px 20px;
  background-position: 0 0, 10px 0, 10px -10px, 0px 10px;
}

.logo-text{
    font-weight: 500;
}
.sparechange-logo{
    max-width: 150px;
}

.card-select:hover{
    background-color: $primary;
    color:$white;
    
    h5{
        color:$white;
    }
    svg{
        color:$white;
    }
}
.donation-row:hover{
    background-color: $gray-100;
    color:$black;
}
.donation-row{
    padding-left: 4.8px !important;
}

.overlapped-hero-text{
    padding: 20px 10px;
    @media screen and (max-width: 959px) {
        padding-bottom: 500px;
    }
    @media screen and (max-width: 500px) {
        padding-bottom: 400px;
    }
    @media screen and (max-width: 400px) {
        padding-bottom: 300px;
    }
}
.overlapped-hero-invite-text{
    padding: 20px 10px;
    @media screen and (max-width: 959px) {
        padding-bottom: 380px;
    }
    @media screen and (max-width: 500px) {
        padding-bottom: 250px;
    }
    @media screen and (max-width: 400px) {
        padding-bottom: 200px;
    }
}
.overlapped-hero-img{
    position:absolute;
    z-index: 10;
    top:-150px;
    @media screen and (max-width: 959px) {
        margin-top:-280px;
    }
    @media screen and (max-width: 500px) {
        margin-top:-180px;
    }
    @media screen and (max-width: 400px) {
        margin-top:-100px;
    }
    img{
        width: 600px;
    } 
}

.overlapped-hero-invite-img{
    position:absolute;
    z-index: 10;
    top:-120px;
    @media screen and (max-width: 959px) {
        margin-top:-200px;
    }
    @media screen and (max-width: 500px) {
        margin-top:-100px;
    }
    @media screen and (max-width: 400px) {
        margin-top:-50px;
    }
    div{
        max-width: 700px;
    } 
}

.loading-overlay{
    position: fixed;
    z-index: 2000;
    // height: 2em;
    // width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.loading-overlay:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  .number-circle {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    padding: 10px;
    background: #fff;
    border: 3px solid #000;
    color: #000;
    text-align: center;
   // font: 32px Arial, sans-serif;
  }

    .number-circle{
        border-radius: 50%;
        height:35px;
        width:35px;
        padding: 0;
        //margin-left: 3px;
        margin-right: 10px;
        color:$white;
        background-color: $black;
        border-color: $black; 
        &.muted{
            background-color: $gray-500;
            border-color: $gray-500; 
        }
        &.active{
            background-color: $white;
            border-color: $white; 
            color:$black;
        }
    }
