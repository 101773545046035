// Used in FAQ
.faq-group {
  border: 0;
  background-color: inherit;
  padding: 0;
};

.faq-question {
 // background-color: $white;
  padding:15px 30px 15px 30px ;
  border-bottom: 1px solid $gray-500;
  //color: $primary;
  font-size: 1rem;
    &.active {
      
  border-bottom: none;
      // border-color: $primary;
      // background-color: $primary;
      // color: $white;
    }
};


.faq-answer {
  padding-top: 0;
  padding-left:67px;
  padding-right:67px;
  padding-bottom:30px;
  border-bottom: 1px solid $gray-500;
  
  @media screen and (max-width: 959px) {
    padding-left:30px;
    padding-right:30px;
}
}