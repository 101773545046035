/*-----------------------------------------------
|   Badge
-----------------------------------------------*/
.badge-soft-yellow{
  color: darken($yellow, 25%);
  background-color:lighten($yellow, 80%);
}
.badge-soft-gray{
  color: $gray-800;
  background-color:$gray-200;
}
.badge-soft-orange{
  color: darken($orange, 15%);
  background-color:lighten($orange, 70%);
}
.badge-soft-green{
  color: darken($green, 15%);
  background-color:lighten($green, 70%);
}
.badge-soft-red{
  color: darken($red, 30%);
  background-color:lighten($red, 75%);
}
.badge-soft-blue{
  color: darken($blue, 20%);
  background-color:lighten($blue, 70%);
}
// @mixin badge-soft-variant($bg, $color) {
//   color: $color;
//   background-color: $bg;

//   &[href] {
//     @include hover-focus {
//       text-decoration: none;
//       color: $color;
//       background-color: darken($bg, 10%);
//       //background-color: $bg;
//     }
//   }
// }

// @each $color, $value in $theme-colors {
//   .badge-soft-#{$color} {
//     @if $color == 'secondary' {
//       @include badge-soft-variant(theme-color-level($color, -10), theme-color-level($color, -0.8));
//     } @else if $color == 'warning' {
//       @include badge-soft-variant(theme-color-level($color, -10), theme-color-level($color, 2.5));
//     } @else if $color == 'danger' {
//       @include badge-soft-variant(theme-color-level($color, -10), theme-color-level($color, 4.5));
//     } @else {
//       @include badge-soft-variant(theme-color-level($color, -10), theme-color-level($color, 4.5));
//     }
//   }
// }
