/*-----------------------------------------------
|   Alert
-----------------------------------------------*/
.alert-green{
  color:darken($green, 25%);
  background-color:lighten($green-light, 30%);
  border-color:darken($green, 25%);
}
.alert-red{
  color:$red;
  background-color:$red-light;
  border-color:$red;
}
.alert-yellow{
  color: darken($yellow, 25%);
  background-color:lighten($yellow, 80%);
  border-color:darken($yellow, 25%);
}

.alert-gray{
  color:$gray-700;
  background-color:$gray-200;
  border-color:$gray-700;
}