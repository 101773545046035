@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.
// $primary:hsl(340, 57%, 64%);
$primary:var(--primary,"#480D7E");
$primary-light:var(--primary_light,"#3d1966");
$primary-darker2:var(--primary_darker2,"#3d1966");
$primary_gradient:var(--primary_gradient,"#3d1966");
$secondary:var(--secondary, "#862B60");
$danger: #d9534f;

// body {
//     font-family: "Open Sans", sans-serif;
//   }

$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-monospace: 'Poppins',sans-serif;
$font-family-base: 'Poppins',sans-serif;

