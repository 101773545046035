// .dashboard {
//   width: 100vw;
//   overflow-x: hidden;
// }

///////////////////// topbar



.dashboard-topbar{
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  height: 100px;
  z-index: 3;
  border-bottom: 5px solid $primary;
  padding: 0px 15px 0px 15px;
  &.mobile{
    padding: 0px 5px 0px 5px;
    height: 70px;
  }
} 

.dashboard-topbar-alt{
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  height: 100px;
  z-index: 3;
  background-color: $primary-darker2;
  padding: 0px 15px 0px 15px;
  &.mobile{
    padding: 0px 5px 0px 5px;
    height: 70px;
  }
} 

.dashboard-hero-alt{
  background-color: $primary-darker2;
} 

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    width: 300px;
    display: flex;
    flex-direction: column ;
    height: calc(100vh - 100px);
    height: calc(var(--vh, 1vh) * 100 - 100px);
    background: $gray-1100;
    color: #fff;
    // margin-left: -250px;
    transition: all 0.5s;
    // padding-top: 57px;
    overflow-x: hidden;
    .nav-item{
      padding: 10px 30px 10px 30px;
    }
    .nav-item:hover {
      color: $primary;
      background: #fff;
    }
    a,
    a:hover,
    a:focus {
      color: inherit;
      text-decoration: none;
      transition: all 0.3s;
    }
    &.mobile{
      position:fixed;
      z-index: 1;
      height: calc(100vh - 70px);
      height: calc(var(--vh, 1vh) * 100 - 70px);
      // height: calc(100vh - 70px);
      // height: calc(var(--vh, 1vh) * 100 -70px);
      &.collapsed {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        svg{
          width: 18px;
        }
      }
    }
    &.collapsed {
      width: 70px;
      min-width: 70px;
      max-width: 70px;
      align-items: center;
      .nav-item{
        padding: 10px 0px 10px 0px;
        .d-flex{
          justify-content: center;
        }
      }
      .sidebar-text{
        display: none;
      }
      // .sidebar-footer{

      // }
    }
  }
  
  .sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
  }

  
  // .sidebar-header h3 {
  //   color: #fff;
  //   padding: 1em;
  // }
  
  .sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  .menu-open {
    background: $primary-gradient;
  }
  


  
  // .items-menu {
  //   color: #fff;
  //   background: $primary;
  // }
  
  // li a.dropdown-toggle::after {
  //   display: inline-flex;
  //   position: relative;
  //   left: 60%;
  //   top: 10%;
  // }
  
  // .sidebar-header > span {
  //   position: relative;
  //   float: right;
  //   margin: 0.5em;
  //   font-size: 2rem;
  //   cursor: pointer;
  //   display: none;
  // }
  .side-menu {
    flex-grow: 4;
   // overflow-y: scroll;

  }
  // .side-menu::-webkit-scrollbar {
  //   width: 10px;
  // }
  
  // .side-menu::-webkit-scrollbar-thumb {
  //   background: #5466b3;
  //   border-radius: 3px;
  // }
  
  // .side-menu::-webkit-scrollbar-thumb:hover {
  //   background: #3a4c99;
  // }
  
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
// .content {
//   padding: 20px;
//   margin-left: 0;
//   height: auto;
// }
.dashboard-content {
  width:100%;
  transition: margin-left .5s;
  padding: 16px;
  height: calc(100vh - 100px);
  height: calc(var(--vh, 1vh) * 100 - 100px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

&.mobile{
  margin-left: 40px;
  padding: 0px;
  height: calc(100vh - 70px);
  height: calc(var(--vh, 1vh) * 100 - 70px);
  opacity: .7;
  filter: blur(4px);
  &.collapsed {
  opacity: 1;
  filter: blur(0);

  }
}
  .content{
    
  width: 1200px;    
  }
}

// @media only screen and (max-width: 720px) {
//   body {
//     overflow: hidden;
//   }

//   .content.is-open {
//     margin-left: 100%;
//   }

//   .sidebar.is-open {
//     min-width: 100%;
//     max-width: 100%;
//     margin-left: 0;
//     transition: all 0.5s, height 0s;
//   }

//   .sidebar.is-open > .sidebar-header span {
//     display: unset;
//   }

//   li a.dropdown-toggle::after {
//     display: inline-block;
//     position: relative;
//     left: 68%;
//   }
// }

