/*-----------------------------------------------
|   Table
-----------------------------------------------*/
.table{
  tfoot > tr > th:first-child,
  thead > tr > th:first-child,
  tr th:first-child,
  tr td:first-child{ padding-left: $card-spacer-x; }
}
.white-space-nowrap{
  width: 1px;
  white-space: nowrap;
}
th{ font-weight: $font-weight-semi-bold; }
td .dropdown-toggle:after{ display: none; }
.table-dashboard{
  overflow: hidden;
  th{ border-bottom-width: 1px !important; }
}

/*---------------------------------------------
Card List Table 
--------------------------------------------*/

$screen-sm-min: 768px;
$shadow: 0 2px 6px rgba(0, 0, 0, .2);
$gray-border: darken(#f8f8f8, 10%);
$gray-light: rgba(0, 0, 0, .35);
$faded-yellow: lighten(#f1c40f, 40%);

// thx, @HugoGiraudel
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@include scrollbars(.15em, slategray);


.table-wrapper {
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;
  //max-width: 300px;
  padding-top: 15px;
  // width: 80%;
  margin: 0 auto 0;
  max-height: 500px;
  overflow-y: scroll;
  position: relative;
  transition: all .2s ease-out;
  // @media(min-width: $screen-sm-min) {
  //   background: white;
  //   box-shadow: $shadow;
  // }
}

.card-list-table {
  table-layout: fixed;
  background: transparent;
  margin-bottom: 0;
  width: 100%;
  thead {
    display: none;
  }
  tbody tr {
    box-shadow: $shadow;
    background: #fff;
    border-bottom: 1px solid $gray-border;
    cursor: pointer;
    display: block;
    padding: 5px 5px;
    margin: 10px;
  }
  
  tbody tr td:first-child{ 
    padding:4px 10px 4px 40%;; }
  tbody td {
    border: 0;
    display: block;
    padding: 4px 10px 4px 40%;
    position: relative;
    &:first-of-type::after {
      visibility: hidden;
    }
    &:after {
      content: '';
      width: calc(100% - 30px);
      display: block;
      margin: 0 auto;
      height: 1px;
      background: $gray-border;
      position: absolute;
      left: 0;
      right: 0;
      top: -6px
    }
    &:before {
      // color: $gray-light;
      // text-transform: uppercase;
      // font-size: .75em;
      content: attr(data-title);
      display: table-cell;
      // font-weight: 500;
      height: 100%;
      left: 15px;
      margin: auto;
      position: absolute;
      vertical-align: middle;
      white-space: nowrap;
      width: 40%;
    }
  }
  thead th {
    text-transform: uppercase;
    font-size: .75em;
    color: $gray-500;
    letter-spacing: .5pt;
  }
}